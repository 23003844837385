import store from '@/store/index'
import Session from '../types/session'

function getSession() {
  return store?.state.user as Session
}

function setSession(newSession: any) {
  store.state.user = newSession
}

export { getSession, setSession }
