import { NavigationGuardNext, Route } from 'vue-router'
import cookies from 'js-cookie'
import tools from '../functions/tools'
import webServices from '../functions/webServicesFacade'
import store from '../store/index'
import { PageI } from '../functions/mainMenu'
import { getSession } from '../functions/getSession'

async function tokenAuthentication() {
  const params = tools.parseParams(window.location.search)
  const token = cookies.get('sid')
  if (params?.sid == undefined && token == undefined) return false
  if (params?.sid && token !== params.sid) cookies.set('sid', params.sid, { expires: 365 })

  try {
    const userResponse = await webServices.getMe()
    store.dispatch('setUser', userResponse.data.data)
    store.dispatch('setAuthentication', true)
    return await store.dispatch('loadDB')
  } catch (e) {
    store.dispatch('setLoadingStatus', 'not loading')
    return false
  }
}

// `pages` is the object holding all the mainMenu info found in mainMenu.ts
// Calculate authorized paths from authorized pages
// Therefore configuring the `pages` object will automatically add navigation guards
function authorizedPaths(authorizedPages: PageI[]): string[] {
  const paths = authorizedPages.reduce((pathsList: string[], page: PageI) => {
    if (!page.children) {
      pathsList.push(page.link)
      return pathsList
    }
    const childrenPaths = []
    page.children.forEach((childPage: PageI): void => {
      childrenPaths.push(childPage.link)
    })
    return pathsList.concat(childrenPaths)
  }, [])

  // assume acces to `/` when access to `/products` is granted
  // as set in the router but not in the main menu
  if (paths.find((path: string): boolean => path === '/products')) paths.push('/')
  return paths
}

// Determine if a user is authenticated and they are allowed by their role
function canNavigate(to: Route): boolean {
  const current_tenant = getSession().current_tenant
  if (getSession().roles[current_tenant]?.includes('sw-admin')) return true
  const authenticated = store.state.authenticated
  const authorizedPathsList = authorizedPaths(store.getters.authorizedPagesList)
  const requestedPath = decodeURIComponent(to.fullPath)
  const authorized = authorizedPathsList.includes(requestedPath)

  // For the links like '/reports?type=stock&filter=&groups=sku.brand&fields=41&period=TODAY&orderby=g0&sortorder=&reportViz=matrix'
  // They may navigate to page stock but this whole link doesn't exist on authorizedPathsList
  // So here the filtering options are allowed for who is allowed to view the page
  if (authenticated && !authorized) {
    const requestedPathFirstPart = requestedPath.split('&')[0]
    for (const path of authorizedPathsList) if (path.split('&')[0] === requestedPathFirstPart) return true

    // For the paths like '/skueditor?masterdata=true&filter=brand=="masterdata"'
    // If the requested link doesn't include masterdata so the user may navigate to that view
    // The user must also be authorized to navigate to the path like /skueditor
    const stringsShouldNotExist = ['masterdata', '/transactions?type', '/reports?type', '/loadbalancer?type']
    if (!stringsShouldNotExist.some((path) => requestedPath.includes(path)) && authorizedPathsList.includes(to.path)) return true
  }

  const alwaysAllowed = ['/login', '/signup'].includes(to.path)
  return (authenticated && authorized) || alwaysAllowed
}

export default async function(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
  if (!store.state.authenticated) await tokenAuthentication()
  if (to.name == 'logout') store.state.editing = false
  if (store.state.editing) {
    store.dispatch('raiseAlert', {
      header: 'stillediting',
      type: 'warning',
      timeout: 5000,
    })
    next(false)
  } else if (canNavigate(to)) {
    next()
  } else if (!store.state.authenticated) {
    next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
  } else if (!authorizedPaths(store.getters.authorizedPagesList).includes(to.fullPath)) {
    next({ path: '/login', query: { popup: 'noRole', redirect: encodeURIComponent(to.fullPath) } })
  } else next()
}
