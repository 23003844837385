import Vue from 'vue'
import Vuex from 'vuex'
import globalStore from './globalStore'
import consts from './consts'
import productFunctions from '@/functions/products'
import { SkuI, hashBrand, deepCopy, buildPropertyMappingFn, getPreferedPropertyMappings, mappingStrategyE } from '@softwear/latestcollectioncore'
import startupDB from './client'
import vuetify from '../plugins/vuetify'
import tools from '../functions/tools'
import userFunctions from '@/functions/users'
import webServices from '@/functions/webServicesFacade'
import preferences from '@/functions/preferences'
import articleStatus from '@/store/modules/articleStatusStore'
import { authorizedPagesList } from '@/functions/mainMenu'
import { swT } from '@/functions/i18n'
import articleStatusConfig from '../functions/articleStatusConfig'
const ensureImpliedProperties = productFunctions.ensureImpliedProperties
import sourceData from '../functions/dashboardData'
import { AuditHeadersI } from '@/types/startupDBClient'
import soundFunctions from '@/functions/soundFunctions'

Vue.use(Vuex)

const storePropertyMappingFnsInState = function(state) {
  const allMappings = getPreferedPropertyMappings(
    state.activeConfig,
    globalStore.getLatestCollectionArray('metabrandsetting'),
    globalStore.getLatestCollectionArray('dataproviderbrandsetting'),
    globalStore.getLatestCollectionArray('tenantbrandsetting')
  )
  state.propertyMappingFn = buildPropertyMappingFn(allMappings, mappingStrategyE.MAP)
  state.propertyCleaningFn = buildPropertyMappingFn(allMappings, mappingStrategyE.CLEAN)
}
const cardAuthorized = function(card, tenant, userRoles) {
  const tenantIDIncluded = card.tenantID.length == 0 || card.tenantID.includes(tenant)
  const roleIncluded = card.role.length == 0 || card.role.some((role) => userFunctions.hasRole(userRoles, role))

  return tenantIDIncluded && roleIncluded
}
export default new Vuex.Store({
  modules: {
    articleStatus,
  },
  state: {
    dialogDisplayed: false,
    loading: false,
    activeConfig: null,
    activeFormValid: false,
    localDocumentIndex: null,
    alerts: [
      {
        state: false,
        header: '',
        type: 'info',
      },
    ],
    attributes: [],
    authenticated: false,
    brands: [], // all latestCollection & tenant brands merged together
    collections: [], // sku collections + some inferred collections
    skuCollections: [], // only collections that come from the sku data
    colors: [],
    drawer: true,
    b2bDrawer: false,
    b2bBasket: {}, // b2bBasket.warehouse.articleCodeSupplier.barcode = qty
    b2bBasketCount: 0, // nr Pieces in b2bBasket
    b2bBasketValue: 0, // total buyPrice in b2bBasket
    b2bBasketUpdateTrigger: 0, // increment this whenever we want to force the basket dependencies to recompute, watching the b2bBasket itself will not trigger because Vue's inability to see net properties.
    b2bSelectedWarehouses: [],
    showBeta: ['127.0.0.1', '192.168.2.7', 'localhost', 'beta.', 'edge.'].some((prefix) => window.location.hostname.startsWith(prefix)),
    editing: false, // used in all components to prevent navigating away from that component when editing
    filter: '',
    hideMainFilter: false,
    reportViz: null,
    rowsInfo: {
      filtered: null,
      total: null,
    },
    inventory: [],
    loadingStatus: 'loading',
    importBusy: false,
    exportBusy: false,
    locale: 'en',
    paginationCurrentPage: 1,
    paginationTotalPages: null,
    foldReportsUI: true,
    reportsFilterFullUI: false,
    beforeAuthLanguage: '',
    selectedProduct: null,
    articleStatusTableViz: 'table',
    mainFilterPrompt: 'filter',
    matrixClipboard: [],
    metadata: null,
    productIsSelected: false,
    networkError: null,
    productGroups: [], // all productgroups
    rawFilter: '',
    productPickerActive: false,
    barcodeScannerMode: false,
    productPicker: '',
    productPickerItems: [],
    appFilterByList: ['articleCodeSupplier', 'articleCode', 'articleDescription', 'articleGroup', 'articleGroupSupplier', 'collection', 'colorFamily'],
    appFilterBySelectedKey: 'articleCodeSupplier',
    scannedBarcode: '',
    sizes: [],
    skuLoading: true,
    skuPromise: null,
    tenantBrands: [],
    reportGroups: articleStatusConfig.groupChoices,
    reportColumns: articleStatusConfig.fieldChoices,
    poFilterDate: [null, null],
    info: {
      showInfoDrawer: false,
      data: [] as any,
    },
    user: {
      name: '',
      roles: {},
      deviceId: '',
      wh: '',
      current_tenant: '',
      username: '',
      settings: {
        tenant: {
          settings: {
            uploadDirectory: '',
          },
        },
        user: {
          id: '',
        },
      },
      tenant: {
        id: '',
        name: '',
        gln: '',
      },
      b2bAccessList: [],
    },
    vatHi: 21,
    vatLo: 9,
    propertyMappingFn: null,
    propertyCleaningFn: null,
    totalNrBarcodes: 0,
    totalNrConsumers: 0,
    lastPulled: {
      inventory: 0,
    },
    picqerWarehouses: [{ value: 0, text: '** SAVE API TOKEN FIRST TO SELECT WAREHOUSE **' }],
    brandOwnerOf: [],
  },
  mutations: {
    SET_TOTAL_NR_BARCODES(state, nr) {
      state.totalNrBarcodes = nr
    },
    COMPUTE_UNIQUE_SKU_PROPERTIES(state, skus) {
      globalStore.setIndexedProducts(productFunctions.buildProductIndexedObject(skus))
      state.attributes = productFunctions.getAttributes(skus)
      state.colors = tools.getUniqueValues(skus.map((e) => e.colorFamily || e.mainColorDescription)).sort()
      state.sizes = tools.getUniqueValues(skus.map((e) => e.size)).sort()
      state.productGroups = tools.getUniqueValues(skus.map((e) => e.articleGroup)).sort()
      const collections = skus.map((e) => e.collection)
      state.skuCollections = tools.getUniqueValues(collections).sort()
      const allCollections = state.skuCollections.concat(['00noos', '00admin'])
      for (let year = 10; year < parseInt(('' + (new Date().getFullYear() + 1)).slice(-2)); year++) allCollections.push(year + 'winter', year + 'summer')
      state.collections = allCollections
      state.brands = productFunctions.combineBrands(state, skus)
      state.tenantBrands = tools.getUniqueValues(skus.map((e) => e.brand || 'unknown'))
    },
    SET_NETWORK_ERROR(state, e) {
      state.networkError = e
      console.log('network error', e)
    },
    SET_AUTHENTICATION(state, value) {
      state.authenticated = value
    },
    SET_LOADING_STATUS(state, status) {
      state.loadingStatus = status
    },
    SET_BRANDS(state, brands) {
      state.brands = brands
    },
    SET_METADATA(state, data) {
      state.metadata = data
    },
    SET_USER(state, user) {
      state.user = user
      if (user.language) state.locale = user.language.slice(0, 2)
    },
    SET_EDITING(state, editing) {
      state.editing = editing
    },
    CLEAR_FILTER(state) {
      state.filter = ''
      state.rawFilter = ''
      state.scannedBarcode = ''
    },
    RAISE_ALERT(state, alert) {
      alert.state = true
      if (alert.sound) soundFunctions.playFailed()

      state.alerts.push(alert)
      vuetify.framework.goTo(0)
      if (alert.timeout) {
        setTimeout(function() {
          alert.state = false
        }, alert.timeout)
      }
    },
  },
  getters: {
    allMonthsOfTheYear(state) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
      // return an array of objects with the month name and the month number
      //  { monthName: 'jan', monthNumber: 1 }

      const months = []
      for (let i = 0; i < 12; i++) {
        const monthName = new Intl.DateTimeFormat(state.locale, { month: 'short' }).format(new Date(2020, i, 1))
        months.push({ monthName, monthNumber: i })
      }
      return months
    },
    prioritizedInfo(state) {
      const filteredInfo = state.info.data.filter((e) => e.priority)
      if (filteredInfo.length) return filteredInfo
      return state.info.data
    },
    translatedReportColumns(state) {
      return state.reportColumns
        .filter((column) => !column.showForB2bChannels || state.activeConfig.salesChannels.mySalesChannels?.value.includes('wholesale'))
        .map((column) => ({ ...column, text: swT(column.text) }))
    },
    reportColumnsAbbreviated(state) {
      return state.reportColumns.map((column) => ({ ...column, text: swT(column.text, `abr`) }))
    },
    translatedReportGroupColumns(state) {
      const reportGroups = []
      for (const attribute of state.activeConfig.products.activeAttributes.value)
        reportGroups.push({
          text: attribute,
          value: `'sku._${attribute}'`,
          reportViz: 'all',
          fieldType: 0,
        })
      return state.reportGroups
        .concat(reportGroups)
        .map((group) => ({ ...group, text: swT(group.text) }))
        .filter((group) => group.reportViz == 'all' || (',' + group.reportViz + ',').includes(',' + state.reportViz + ','))
        .filter((group) => {
          if (!group.showWhenPreference) return true
          return state.activeConfig.reports[group.showWhenPreference]?.value
        })
    },
    years() {
      const years = []
      const now = new Date()
      const thisYear = now.getFullYear()
      for (let year = thisYear - 3; year <= thisYear; year++) years.push(year)
      return years
    },
    brandAccess: (state) => (selectedBrandName) => {
      const tenantId = state.user.current_tenant
      if (tenantId == 'master') return { hasAccess: true }
      const roles = state.user.roles[tenantId]
      if (userFunctions.hasRole(roles, 'sw')) return { hasAccess: true }
      if (!state.activeConfig.partnerchannels.latestcollection.value) return { hasAccess: false }
      const selectedBrandData = state.brands.find((brand) => brand.collection === selectedBrandName)
      if (!selectedBrandData) return { hasAccess: false }
      if (!selectedBrandData.ownerTenantId || selectedBrandData.ownerTenantId === state.user.current_tenant || !selectedBrandData.associates) return { hasAccess: true }
      const isAssociate = selectedBrandData.associates.find((associate) => associate.tenantId === state.user.current_tenant)
      if (!isAssociate) return { hasAccess: false }
      return { status: isAssociate?.status, hasAccess: isAssociate?.status === 'approved' }
    },
    roles(state) {
      return state.user.roles[state.user.current_tenant] || []
    },
    isDemo(state) {
      return state.user.roles[state.user.current_tenant]?.includes('products-demo')
    },
    isDeviceUser(state) {
      return !!state.user.deviceId && !!state.user.wh
    },
    brandCollection: (state) => (brand) => {
      return state.brands.find((e) => hashBrand(e.name) == brand || e?.collection == brand || e.aliases?.includes(brand))
    },
    mapSubSizes(state) {
      return state.activeConfig.products.mapSubSizes.value
    },
    sizeOrdering(state) {
      return state.metadata.sizeOrdering.data
    },
    uploadDirectory(state) {
      return state.user.settings.tenant.settings.uploadDirectory
    },
    authorizedPagesList(state, getters) {
      if (getters.roles.length === 0) return []
      const authorizedPages = authorizedPagesList(getters.roles, state.showBeta)
      if (process.env.NODE_ENV === 'development') {
        authorizedPages.push({
          name: 'developertools',
          link: '/dev',
          icon: 'mdi-dev-to',
          shortcut: 'Alt+F12',
        })
      }
      return authorizedPages
    },
    dashBoardSourceData(state) {
      const tenant = state.user.current_tenant
      const userRoles = state.user.roles[tenant]
      return sourceData.filter((card) => {
        return cardAuthorized(card, tenant, userRoles)
      })
    },
  },
  actions: {
    computeUniqueSkuProperties({ commit }, payload) {
      commit('COMPUTE_UNIQUE_SKU_PROPERTIES', payload)
    },
    setLoadingStatus({ commit }, payload) {
      commit('SET_LOADING_STATUS', payload)
    },
    setAuthentication({ commit }, payload) {
      commit('SET_AUTHENTICATION', payload)
    },
    setUser({ commit }, payload) {
      commit('SET_USER', payload)
    },
    setEditing({ commit }, payload) {
      commit('SET_EDITING', payload)
    },
    clearFilter({ commit }) {
      commit('CLEAR_FILTER')
    },
    raiseAlert({ commit }, payload) {
      commit('RAISE_ALERT', payload)
    },
    async ensureConfigDocs({ dispatch }) {
      const system = globalStore.getLatestCollectionObject('system')
      if (!('tenantPreference' in system)) {
        await dispatch('updateObjects', {
          api: globalStore.getLatestCollectionAPI('system'),
          data: [{ id: 'tenantPreference', ...consts.allConfig }],
        })
      }
    },
    async ensureTenantCollection({ state }, payload) {
      const collection = globalStore.getLatestCollectionArray(payload)
      if (collection.length == 0) {
        const tenantId = state.user.current_tenant
        globalStore.setLatestCollectionAPI('eventlog', await startupDB({ url: '/' + tenantId + '/eventlog' }))
        globalStore.getLatestCollectionArray('eventlog')
      }
    },
    async refreshDB() {
      await globalStore.getLatestCollectionAPI('purchaseorder')?.pull()
      await globalStore.getLatestCollectionAPI('transaction')?.pull()
    },
    /* eslint-disable */
    async updateObjects(
      { state },
      payload: {
        data: any[]
        api: any
        silent?: boolean
        auditHeaders?: AuditHeadersI
      }
    ) {
      /* eslint-enable */
      // Split objects in two groups. New objects (without id) should be updated/created (sending the whole object to the server),
      // existing objects (with id) can be patched (sending only the diff to the server)

      const objects2Patch = []
      const objects2Create = []

      payload.data.forEach((obj) => {
        if (!obj.id || !payload.api?.checkPoint.data[obj.id]) objects2Create.push(obj)
        else objects2Patch.push(obj)
      })
      // In some cases the function will create and patch in one go
      // Cannot interrupt the flow
      if (objects2Create.length > 0) {
        try {
          await payload.api.create(objects2Create, payload?.auditHeaders)
          if (!payload.silent)
            this.dispatch('raiseAlert', {
              header: 'data_saved',
              type: 'success',
              timeout: 3000,
            })
        } catch (e) {
          this.dispatch('raiseAlert', {
            header: (e as any).message,
            body: (e as any).message + '.tip',
            type: 'error',
            text: (e as any).response.data.error,
          })
          return false
        }
      }

      if (objects2Patch.length > 0) {
        try {
          if (await payload.api.patch(objects2Patch, payload?.auditHeaders))
            if (!payload.silent)
              this.dispatch('raiseAlert', {
                header: 'data_updated',
                type: 'success',
                timeout: 3000,
              })
        } catch (e) {
          console.log(e)
          this.dispatch('raiseAlert', {
            header: (e as any).message,
            body: (e as any).message + '.tip',
            type: 'error',
            text: (e as any).response.data.error,
          })
          return false
        }
      }
      return true
    },
    async updateSkuObjects(
      { state },
      payload: {
        data: any[]
        audit?: string
      }
    ) {
      const barcodesToSave = payload.data
      const success = await this.dispatch('updateObjects', {
        api: globalStore.getLatestCollectionAPI('sku'),
        data: barcodesToSave,
        auditHeaders: { 'x-transaction-audit-source': payload.audit },
      })
      if (success) {
        productFunctions.prepareSkusForUI(barcodesToSave, state)
        barcodesToSave.forEach((sku) => (sku.usedByTenant = true))
        return true
      }
      return false
    },
    /* eslint-disable */
    async deleteObjects({ state }, payload) {
      /* eslint-enable */
      try {
        if (await payload.api.delete(payload.data, payload?.auditHeaders))
          if (!payload.silent)
            this.dispatch('raiseAlert', {
              header: 'data_deleted',
              type: 'success',
              timeout: 3000,
            })
      } catch (e) {
        this.dispatch('raiseAlert', {
          header: (e as any).message,
          body: (e as any).message + '.tip',
          type: 'error',
          text: (e as any).response.data.error,
        })
        return false
      }
      return true
    },
    async updatePreference({ state }, { key, value }) {
      const activeConfig = deepCopy(state.activeConfig)

      const keyLevels = key.split('.')
      const block = keyLevels[0]
      const config = keyLevels[1]
      if (!activeConfig[block] || !activeConfig[block][config]) throw `Preference key ${key} does not exist`
      activeConfig[block][config].value = value
      if (activeConfig[block][config].level == 'tenant') await preferences.saveTenantPreferences(this, activeConfig)
      if (activeConfig[block][config].level == 'user') await preferences.saveUserPreferences(this, activeConfig)
      this.dispatch('loadSettings')
    },
    async loadBrand({ getters, state }, { brand = '', dataProvider }) {
      if (brand == '' || !getters.brandAccess(brand).hasAccess) {
        globalStore.resetLatestCollectionAPI('selectedbrand')
        return true
      }
      try {
        const apiInfo = globalStore.getLatestCollectionAPI('selectedbrand')
        if (apiInfo?.brand == brand && !dataProvider) {
          const newBrandData = await globalStore.getLatestCollectionAPI('selectedbrand')?.pull()
          const newSkuData = await globalStore.getLatestCollectionAPI('sku')?.pull()
          if (!newSkuData && !newBrandData) return false
        }
        globalStore.resetLatestCollectionAPI('selectedbrand')
        const brandAPI = await startupDB({
          url: (dataProvider ? '/restricted/' + dataProvider + '/' : '/brands/') + brand,
        })
        brandAPI.brand = brand
        brandAPI.dataProvider = dataProvider
        globalStore.setLatestCollectionAPI('selectedbrand', brandAPI)
      } catch (e) {
        // Show error that latestCollection data cannot be loaded
      }
      const propertyMappingFn = this.state.propertyMappingFn
      const brandName = globalStore.getLatestCollectionObject('metabrandsetting')[brand]?.name
      const selectedBrandSkus = globalStore.getLatestCollectionArray('selectedbrand')
      selectedBrandSkus.forEach((sku) => {
        sku.brand = brandName
        sku.BRANDHASH = brand
      })
      selectedBrandSkus.forEach(propertyMappingFn)
      selectedBrandSkus.forEach(ensureImpliedProperties)
      const tenantId = state.user.current_tenant
      const roles = state.user.roles[tenantId]
      if (userFunctions.hasRole(roles, 'lc-b2b,lc-data-only')) {
        const b2bSkus = globalStore.getB2bSkus()
        selectedBrandSkus.forEach((sku) => (b2bSkus[sku.id] = sku))
      }
      return true
    },
    async getBarcode({ state, commit }, payload) {
      try {
        const res = await webServices.getSku(state, payload.barcode, false)
        const skus = globalStore.getLatestCollectionArray('sku')
        await globalStore.getLatestCollectionAPI('sku')?.pull()
        commit('SET_BRANDS', productFunctions.combineBrands(state, skus))
        payload.callback(res.data)
      } catch (error) {
        payload.callback(null)
        return
      }
    },
    loadSettings({ state }) {
      const system = globalStore.getLatestCollectionObject('system')
      const userPreference = globalStore.getLatestCollectionObject('userpreference')

      state.activeConfig = userFunctions.loadPreferences(state.locale, userPreference[state.user.settings.user.id] || {}, system.tenantPreference || {})
    },
    async loadDB({ state, commit, dispatch }) {
      try {
        commit('SET_LOADING_STATUS', 'loading')
        // Setup connections with statupDB for all the collections we need.
        // Keep references to them in globalStore, not in Vuex. We do this because the collections are large so making
        // that data reactive is too expensive. We use getter- and setter-functions in globalStore to get data out when we need to
        // make it reactive.
        const tenantId = state.user.current_tenant

        for (const tenantResource of ['warehouse', 'inventorymanagement', 'system', 'userpreference', 'tenantbrandsetting', 'layout', 'campaign']) {
          globalStore.setLatestCollectionAPI(tenantResource, await startupDB({ url: `/${tenantId}/${tenantResource}` }))
        }

        for (const metaResource of ['metadata', 'metabrandsetting', 'dataproviderbrandsetting', 'layout:masterlayout']) {
          const [resource, alias] = metaResource.split(':')
          globalStore.setLatestCollectionAPI(alias || resource, await startupDB({ url: `/${resource}` }))
        }

        const roles = state.user.roles[tenantId]
        if (userFunctions.hasRole(roles, 'sw')) {
          globalStore.setLatestCollectionAPI('inbox', await startupDB({ url: '/inbox' }))
        } else {
          globalStore.setLatestCollectionAPI('inbox', await startupDB({ url: '/' + tenantId + '/inbox' }))
        }

        await dispatch('ensureConfigDocs')
        dispatch('loadSettings')

        const metadata = globalStore.getLatestCollectionObject('metadata')
        commit('SET_METADATA', metadata)
        globalStore.setMetadata(metadata)
        const metabrandsetting = globalStore.getLatestCollectionArray('metabrandsetting')
        commit(
          'SET_BRANDS',
          metabrandsetting.filter((b) => !b.hideBrand)
        )
        console.log(state.user.current_tenant)
        state.brandOwnerOf = metabrandsetting.filter((b) => b.ownerTenantId == state.user.current_tenant).map((b) => b.collection)

        storePropertyMappingFnsInState(state)
        const procesSkus = function(skus: SkuI[]) {
          state.skuLoading = false
          productFunctions.prepareSkusForUI(skus, state)

          commit('COMPUTE_UNIQUE_SKU_PROPERTIES', skus)
          commit('SET_TOTAL_NR_BARCODES', skus.length)
        }

        state.skuPromise = new Promise((resolveSkuPromise) => {
          if (!userFunctions.hasRole(roles, 'lc-b2b')) {
            const skuDataPromise = startupDB({ url: '/' + tenantId + '/sku' })
            skuDataPromise.then((data) => {
              globalStore.setLatestCollectionAPI('sku', data)
              procesSkus(globalStore.getLatestCollectionArray('sku'))
              resolveSkuPromise(true)
            })
          } else {
            state.skuLoading = false
            resolveSkuPromise(true)
          }
        })
        for (const tenantResource of ['inventory']) {
          globalStore.setLatestCollectionAPI(tenantResource, await startupDB({ url: `/${tenantId}/${tenantResource}` }))
        }

        commit('SET_LOADING_STATUS', 'not loading')
        return true
      } catch (e) {
        commit('SET_NETWORK_ERROR', e)
        commit('SET_LOADING_STATUS', 'not loading')
        return false
      }
    },

    updateInfoData({ state }, { owner, title = '', drawerContent = '', tooltipContent = '', component, remove = false, priority = false, clearAll = false }) {
      if (clearAll) {
        state.info.data = []
        state.info.showInfoDrawer = false
        return
      }

      if (!owner) return

      let infoOwners

      if (state.info.data.length) {
        infoOwners = state.info.data.map((e) => e.owner)
      }

      if (remove) {
        const index = infoOwners.indexOf(owner)
        state.info.data.splice(index, 1)
        return
      }

      state.info.data.push({ owner, title, drawerContent, tooltipContent, priority, component })
    },
  },
})
