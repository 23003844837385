import globalStore from '../store/globalStore'
import webServices from '../functions/webServicesFacade'
import { getSession } from '../functions/getSession'

const saveTenantPreferences = async function(store, preferences) {
  // Save the tenant settings to the system collection
  const systemOK = await store.dispatch('updateObjects', {
    api: globalStore.getLatestCollectionAPI('system'),
    data: [{ id: 'tenantPreference', ...preferences }],
    silent: true,
  })

  if (systemOK) return preferences
  else return null
}
const saveUserPreferences = async function(store, preferences) {
  // Save the user settings to the user collection
  const userPreferenceOK = await store.dispatch('updateObjects', {
    api: globalStore.getLatestCollectionAPI('userpreference'),
    data: [{ ...preferences, id: getSession().settings.user.id }],
    silent: true,
  })

  if (userPreferenceOK) return preferences
  else return null
}
const savePreferences = async function(store, preferences) {
  // Save the tenant settings to the system collection
  const systemOK = saveTenantPreferences(store, preferences)
  const userPreferenceOK = saveUserPreferences(store, preferences)

  if (systemOK && userPreferenceOK) return preferences
  else return null
}

const getPicqerWarehouses = async function(store) {
  const warehouses = (await webServices.getPicqerData('warehouses')).data
  if (!warehouses) return
  store.state.picqerWarehouses = warehouses.map((warehouse) => ({ text: warehouse.name, value: warehouse.idwarehouse }))
}
export default {
  savePreferences,
  saveTenantPreferences,
  saveUserPreferences,
  onFocusHooks: {
    getPicqerWarehouses: getPicqerWarehouses,
  },
}
