<template>
  <v-container fill-height fluid class="ma-0 pa-0 hero">
    <nav>
      <v-app-bar app flat style="background-color: #0000">
        <v-spacer></v-spacer>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="min-width: 26px !important" color="white" data-test="changeLanguageButton" small v-bind="attrs" v-on="on">
                {{ language }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in languages" :key="index" @click="setLanguage(item.value)">
                <v-list-item-title :data-test="`languageOption-${item.value}`">{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>
    </nav>
    <v-row justify="center" align="center" class="login-form">
      <v-card :class="formWidth" class="pa-4" elevation="8">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
          <div v-if="!showPasswordResetUsernameInput && !showPasswordResetPasswordInputs">
            <v-row class="mx-1 mt-1 mb-4">
              <v-img max-height="200px" max-width="250px" class="mx-auto" :src="logoUrl"></v-img>
            </v-row>
            <v-card-text class="pa-2">
              <v-text-field
                v-if="mfaToken == null"
                ref="usernameField"
                v-model="username"
                outlined
                dense
                data-test="usernameField"
                type="text"
                :rules="usernameRules"
                class="px-1"
                :label="swT('email_or_username')"
                persistent-placeholder
                required
                @keyup.native.enter="login"
              ></v-text-field>

              <v-text-field
                v-if="mfaToken == null"
                v-model="password"
                outlined
                dense
                data-test="passwordField"
                :append-icon="showPasswordCharacters ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPasswordCharacters ? 'text' : 'password'"
                :rules="passwordRules"
                class="px-1"
                :label="swT('password')"
                persistent-placeholder
                required
                @keyup.native.enter="login"
                @click:append="showPasswordCharacters = !showPasswordCharacters"
              ></v-text-field>
              <v-text-field
                v-show="mfaToken != null"
                ref="oneTimePasswordField"
                v-model="oneTimePassword"
                outlined
                dense
                data-test="oneTimePasswordField"
                type="text"
                class="px-1"
                :label="swT('one_time_password')"
                persistent-placeholder
                required
                @keyup.native.enter="login"
              ></v-text-field>

              <v-row class="py-4 d-flex justify-center">
                <v-btn color="primary" data-test="loginButton" @click="login">
                  {{ swT('login') }}
                </v-btn>
              </v-row>
              <v-row class=" d-flex flex-column align-center justify-center">
                <v-btn color="primary" text @click="$router.push('/signup')">{{ swT('signup') }}</v-btn>
                <v-btn text small color="primary" class="mb-2" @click="showPasswordResetUsernameInput = !showPasswordResetUsernameInput">
                  {{ swT('resetPassword') }}
                </v-btn>
                <p class="text-center blue--text font-weight-bold">{{ message }}</p>
                <p class="text-center red--text font-weight-bold">{{ error }}</p>
              </v-row>
            </v-card-text>
          </div>
          <div v-if="showPasswordResetUsernameInput">
            <v-card-title>{{ swT('resetPassword') }}</v-card-title>
            <v-card-text class="pa-2">
              <v-row class="py-1 d-flex flex-column align-center justify-center">
                <v-text-field
                  v-if="showPasswordResetUsernameInput"
                  v-model="username"
                  outlined
                  dense
                  :label="swT('email_or_username')"
                  :rules="usernameRules"
                  class="px-1"
                  persistent-placeholder
                  @keyup.native.enter="requestResetPassword"
                ></v-text-field>
                <v-btn color="primary" class="mb-2" @click="requestResetPassword()">
                  {{ swT('requestEmail') }}
                </v-btn>
                <p class="text-center red--text font-weight-bold">{{ error }}</p>
                <v-btn text small color="primary" class="mb-2" @click="returnToLogin('requestResetPassword')">
                  {{ swT('login') }}
                </v-btn>
              </v-row>
            </v-card-text>
          </div>
          <div v-if="showPasswordResetPasswordInputs">
            <v-card-title>{{ swT('resetPassword') }}</v-card-title>
            <v-card-text class="pa-2">
              <v-row class="py-1 d-flex flex-column align-center justify-center">
                <v-text-field
                  v-model="newPassword"
                  :append-icon="showPasswordCharacters ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[resetPasswordRules.required, resetPasswordRules.minimal_length]"
                  :type="showPasswordCharacters ? 'text' : 'password'"
                  outlined
                  dense
                  class="px-1"
                  data-test="passwordResetField"
                  persistent-placeholder
                  :label="swT('password')"
                  @click:append="showPasswordCharacters = !showPasswordCharacters"
                  @keyup.native.enter="resetPassword"
                ></v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="showPasswordCharacters ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[resetPasswordRules.required, resetPasswordRules.minimal_length, resetPasswordRules.passwordMatch]"
                  :type="showPasswordCharacters ? 'text' : 'password'"
                  outlined
                  dense
                  class="px-1"
                  data-test="confirmPasswordResetField"
                  persistent-placeholder
                  :label="swT('confirm_password')"
                  @click:append="showPasswordCharacters = !showPasswordCharacters"
                  @keyup.native.enter="resetPassword"
                ></v-text-field>
                <v-btn color="primary" class="mb-2" data-test="resetPasswordButton" @click="resetPassword()">
                  {{ swT('resetPassword') }}
                </v-btn>
                <p class="text-center red--text font-weight-bold" :data-test="`error-${!!error}`">{{ error }}</p>
                <v-btn text small color="primary" class="mb-2" @click="returnToLogin('resetPassword')">
                  {{ swT('login') }}
                </v-btn>
              </v-row>
            </v-card-text>
          </div>
        </v-form>
      </v-card>
    </v-row>
    <v-overlay v-if="loadingStatus" class="overlay">
      <transition name="fade" mode="out-in">
        <v-img src="../assets/loading/Loading_Barcode.gif" height="100%" width="100%"></v-img>
      </transition>
    </v-overlay>
  </v-container>
</template>

<script>
import { swT } from '@/functions/i18n'
import authentication from '@/functions/authentication'
import cookies from 'js-cookie'
import language from '@/functions/language'
import consts from '@/store/consts'
import whitelabelFunctions from '@/functions/whitelabel'

export default {
  data() {
    return {
      swT,
      valid: true,
      username: '',
      checkbox: false,
      usernameRules: [(v) => !!v || swT('required')],
      password: '',
      passwordRules: [(v) => !!v || swT('required')],
      resetPasswordRules: {
        required: (value) => !!value || swT('required'),
        minimal_length: (v) => v.length >= 10 || swT('minimal_10_characters'),
        passwordMatch: (v) => v === this.newPassword || swT('password_must_match'),
      },
      language: this.$store.state.locale,
      languages: consts.allConfig.UISettings.language.items,
      showPasswordResetUsernameInput: false,
      showPasswordResetPasswordInputs: false,
      showPasswordCharacters: false,
      newPassword: '',
      confirmPassword: '',
      message: '',
      error: '',
      mfaToken: null,
      oneTimePassword: '',
      loadingStatus: false,
    }
  },
  computed: {
    logoUrl() {
      return whitelabelFunctions.siteLogo(window.location.href)
    },
    formWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '' : 'wide'
    },
  },
  mounted() {
    if (this.$route.query.popup === 'noRole') {
      this.$store.dispatch('raiseAlert', {
        header: 'youNeedADifferentRole',
        type: 'warning',
        timeout: 3000,
      })
    }

    if (this.$route.query?.username?.length > 0 && this.$route.query?.key?.length > 0) {
      this.showPasswordResetPasswordInputs = true
    }
    if (this.$route.query?.lang?.length > 0) {
      if (this.languageExists(this.$route.query.lang)) {
        this.setLanguage(this.$route.query?.lang)
      }
    }
  },
  methods: {
    returnToLogin(view) {
      if (view == 'requestResetPassword') this.showPasswordResetUsernameInput = !this.showPasswordResetUsernameInput
      if (view == 'resetPassword') this.showPasswordResetPasswordInputs = !this.showPasswordResetPasswordInputs

      this.error = ''
      this.$refs.form.reset()
    },
    async resetPassword() {
      if (!this.$refs.form.validate()) return
      this.loadingStatus = true

      const response = await authentication.resetPassword(this.language, this.$route.query.key, this.$route.query.username, this.newPassword, this.confirmPassword)
      this.message = ''
      this.error = ''

      if (response != null && Object.keys(response).length > 0) {
        if (response.data.response == 'ok') {
          this.showPasswordResetPasswordInputs = !this.showPasswordResetPasswordInputs
          this.message = swT('password_reset_success')
        } else {
          this.error = response.data.error
        }
      } else {
        this.error = swT('something_wrong_try_again_later')
      }
      this.loadingStatus = false
    },
    languageExists(languageCode) {
      return this.languages.some((language) => language.value === languageCode)
    },
    setLanguage(languageCode) {
      if (this.languageExists(languageCode)) {
        this.language = language.setLanguage(this.$store, languageCode)
        this.$store.state.beforeAuthLanguage = languageCode
      }
    },
    async requestResetPassword() {
      if (!this.$refs.form.validate()) return
      this.loadingStatus = true

      const response = await authentication.requestResetPassword(this.language, this.username)
      this.message = ''
      this.error = ''

      if (response != null && Object.keys(response).length > 0) {
        if (response.data.response == 'ok') {
          this.showPasswordResetUsernameInput = !this.showPasswordResetUsernameInput
          this.message = swT('check_mailbox_to_finish_process')
        } else {
          this.error = response.data.error
        }
      } else {
        this.error = swT('something_wrong_try_again_later')
      }

      this.loadingStatus = false
    },
    async login() {
      if (!this.$refs.form.validate()) return

      if (this.mfaToken) {
        const response = await authentication.validateOTP(this.mfaToken, this.oneTimePassword)
        if (response !== null && response.sid) {
          this.$store.dispatch('raiseAlert', {
            header: 'loginsuccess',
            type: 'success',
            timeout: 3000,
          })
          this.mfaToken = null
          this.$refs.form.reset()
          this.switchToken(response.sid)
        }
        if (response == null) {
          this.$store.dispatch('raiseAlert', {
            header: 'loginfailed',
            type: 'error',
            timeout: 3000,
          })
        }
        return
      }
      const response = await authentication.authenticate(this.username, this.password)
      if (response !== null && response.sid) {
        this.$store.dispatch('raiseAlert', {
          header: 'loginsuccess',
          type: 'success',
          timeout: 3000,
        })
        this.switchToken(response.sid)
      }
      if (response !== null && response['2FAToken']) {
        this.mfaToken = response['2FAToken']
        this.$nextTick(() => {
          this.$refs.oneTimePasswordField.focus()
        })
        return
      }
      if (response === null || Object.keys(response).length === 0) {
        this.$store.dispatch('raiseAlert', {
          header: 'loginfailed',
          type: 'error',
          timeout: 3000,
        })
      }
      this.password = ''
      if (this.mfaToken) this.$refs.oneTimePasswordField.focus()
      else this.$refs.usernameField.focus()
    },

    switchToken(token) {
      this.loadingStatus = true
      this.$nextTick(() => {
        if (cookies.get('sid') != token) cookies.set('sid', token, { expires: 365 })
        this.$router.push({
          path: this.$route.query.redirect || '/',
        })
      })
    },
  },
}
</script>

<style scoped>
.wide {
  min-width: 480px;
}
.hero {
  background: url('https://picsum.photos/1920/1080?random');
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.login-form {
  max-width: 100vw !important;
  margin: 0;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
