const isQliqpoint = function(url) {
  return url.includes('qliqpoint.fashion') || url.includes('qliqpoint.lc')
}
const isFashionUnited = function(url) {
  return url.includes('fashionunited.latestcollection')
}

const siteLogo = function(url: string): string {
  if (isQliqpoint(url)) return '/img/qplogotr.png'
  if (isFashionUnited(url)) return '/img/fashionunited.png'
  if (url.includes('swretail.latestcollection')) return '/img/swretail.png'
  if (url.includes('mplus.latestcollection')) return '/img/mplus.png'
  return '/img/lclogotr.png'
}
const siteWatermark = function(url: string): string {
  if (isQliqpoint(url)) return '/img/qplogotr.png'
  if (isFashionUnited(url)) return '/img/fashionunited.png'
  if (url.includes('swretail.latestcollection')) return '/img/swretail.png'
  if (url.includes('mplus.latestcollection')) return '/img/mplus.png'
  return '/img/barcode-logo.png'
}

const teamViewerLink = function(url: string): string {
  if (isQliqpoint(url)) return 'https://custom.teamviewer.com/qliqpoint'
  if (url.includes('latestcollection.fashion') || url.includes('127.0.0.1')) return 'https://get.teamviewer.com/latestcollection'
  return ''
}
const orderMethodForDomain = function(url: string): string {
  if (isFashionUnited(url)) return 'FASHION_UNITED'
  return ''
}

export default {
  siteLogo,
  siteWatermark,
  teamViewerLink,
  orderMethodForDomain,
}
